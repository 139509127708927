import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  useSocketConnectionStore,
  useToastStore,
  useUserStore,
} from '@/stores';
import {
  shardedSmsContactsSchema,
  type SharedSmsContact,
  type SharedSmsContactResponse,
  type SharedSMSMessage,
  type SharedSmsMessageDb,
  sharedSmsMessagesSchema,
  sharedSmsResponseSchema,
  type TypingResponse,
} from '@/schemas';
import { db, phoneMasked } from '@/utils';
import { liveQuery, type Observable, type Subscription } from 'dexie';
import { RouteName, Time } from '@/enums';
import ContactIcon from '@/assets/images/icon-contacts.svg';
import { useRouter } from 'vue-router';
import { useArray } from '@/composables';

interface SharedSmsTyping {
  id: number;
  isTyping: boolean;
  name: string;
}

type Typing = Record<string, SharedSmsTyping[]>;

export const useSharedSmsStore = defineStore('shared-sms', () => {
  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const socket = computed(() => socketConnectionStore.ioClient);
  const toastStore = useToastStore();
  const router = useRouter();

  const fullContacts = ref<SharedSmsContact[]>([]);
  const contacts = ref<SharedSmsContact[]>([]);
  const messages = ref<SharedSMSMessage[]>([]);
  const subscription = ref<Subscription>();
  const loading = ref(true);

  const activeFrom = ref('');
  const activeTo = ref('');

  const hasNewSms = ref(false);

  const timeout = ref<ReturnType<typeof setTimeout>>();
  const typing = ref<Typing>({});

  const name = computed(() => {
    return (number: string) => {
      const contact = userStore.getImportedContactByNumber(number);

      return contact ? `${contact.firstName} ${contact.lastName}`.trim() : '';
    };
  });

  function sortContacts() {
    fullContacts.value.sort(
      (a, b) => b.lastMessageAt.getTime() - a.lastMessageAt.getTime(),
    );
  }

  function sendSms(content: string) {
    socket.value?.emit(
      'post-sms',
      {
        src_user: userStore.user.userId,
        src_number: activeFrom.value,
        dst_number: activeTo.value,
        account_code: userStore.currentAccount.accountCode,
        content,
      },
      async (response: unknown) => {
        const { findIndexByValue, removeElementByIndex } = useArray(
          fullContacts.value,
        );

        const index = findIndexByValue('number', 'New message');
        if (index !== -1) {
          hasNewSms.value = false;
          removeElementByIndex(index);
          contacts.value = fullContacts.value;
        }

        const data = response as unknown as SharedSmsMessageDb;

        if (data.type === 'sms-shared') {
          await db.shared_sms.bulkPut([data]);

          updateLastMessageByContact(data);
        }
      },
    );
  }

  function getContacts() {
    socket.value?.emit(
      'list-all-numbers-shared',
      {
        account_code: userStore.currentAccount.accountCode,
        user: userStore.user.userId,
      },
      (response: SharedSmsContactResponse[]) => {
        const { findIndexByValue } = useArray(fullContacts.value);

        let contact: SharedSmsContact | null = null;
        const index = findIndexByValue('number', 'New message');

        if (index !== -1) {
          contact = fullContacts.value.slice(index, index + 1)[0];
        }

        fullContacts.value = shardedSmsContactsSchema.parse(response);
        sortContacts();

        contacts.value = fullContacts.value;

        if (contact) {
          fullContacts.value.unshift(contact);
          contacts.value.unshift(contact);
        }

        if (fullContacts.value.length === 0) {
          addNewSms();
        }
      },
    );
  }

  function syncInitialMessages(numbers: string[]) {
    return new Promise((resolve) => {
      socket.value?.emit(
        'sync-numbers-initial',
        {
          user: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          numbers,
          type: 'sms-shared',
        },
        async (response: { data: SharedSmsMessageDb[] }) => {
          await db.shared_sms.bulkPut(response.data);
          resolve(true);
        },
      );
    });
  }

  function loadMoreMessages() {
    if (messages.value.length === 0) return;

    return new Promise((resolve) => {
      socket.value?.emit(
        'sync-numbers-batch',
        {
          user: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          numbers: [activeTo.value],
          data_ref: messages.value[0].createdAt,
          operation: 'oldest',
          type: 'sms-shared',
        },
        async (response: { data: SharedSmsMessageDb[] }) => {
          loading.value = true;

          if (response.data.length > 0)
            await db.shared_sms.bulkPut(response.data);

          resolve(true);
        },
      );
    });
  }

  function checkSmsRead(number: string) {
    const { findIndexByValue } = useArray(fullContacts.value);

    const index = findIndexByValue('number', number);

    if (index !== -1 && fullContacts.value[index].messagesNotRead === 0) return;

    socket.value?.emit('check-sms-unknown-read', {
      user: userStore.user.userId,
      account_code: userStore.currentAccount.accountCode,
      contact_number: number,
      type: 'sms-shared',
    });

    if (index !== -1) {
      fullContacts.value[index].messagesNotRead = 0;
      contacts.value = fullContacts.value;
    }
  }

  function addNewSms() {
    const { findIndexByValue } = useArray(fullContacts.value);

    const index = findIndexByValue('number', 'New message');
    activeTo.value = '';

    if (index !== -1) return;

    hasNewSms.value = true;

    const contact = {
      firstMessageAt: new Date(),
      isDirectory: false,
      lastMessage: {
        content: '',
        createdAt: new Date(),
        dstNumber: 'New message',
        dstUser: null,
        id: 'New message',
        srcNumber: 'New message',
        srcUser: userStore.user.userId,
        status: [{ status: 'New message', date: new Date() }],
        type: 'sms-shared',
      },
      lastMessageAt: new Date(),
      number: 'New message',
      numberHash: 'New message',
      type: 'sms-shared',
      messagesNotRead: 0,
    } satisfies SharedSmsContact;

    fullContacts.value.unshift(contact);
    contacts.value = fullContacts.value;
  }

  function removeNewMessage() {
    const { findIndexByValue, removeElementByIndex } = useArray(
      fullContacts.value,
    );

    const index = findIndexByValue('number', 'New message');
    if (index !== -1) {
      removeElementByIndex(index);
      contacts.value = fullContacts.value;
    }

    hasNewSms.value = false;
  }

  function updateLastMessageByContact(message: SharedSmsMessageDb) {
    const fullContactIndex = fullContacts.value.findIndex((c) =>
      [message.src_number, message.dst_number].includes(c.number),
    );

    if (fullContactIndex !== -1) {
      fullContacts.value[fullContactIndex].lastMessage =
        sharedSmsResponseSchema.parse(message);
      fullContacts.value[fullContactIndex].lastMessageAt =
        fullContacts.value[fullContactIndex].lastMessage.createdAt;
    }

    getContacts();
  }

  function getSmsObservable(numbers: string[], recipient: string) {
    return liveQuery(async () => {
      const keySent = numbers.map((n) => [n, recipient]);
      const keyRecipient = numbers.map((n) => [recipient, n]);

      return db.shared_sms
        .where('[src_number+dst_number]')
        .anyOf([...keySent, ...keyRecipient])
        .sortBy('created_at');
    });
  }

  function getSmsDb(observable: Observable<SharedSmsMessageDb[]>) {
    subscription.value = observable.subscribe(async (data) => {
      messages.value = sharedSmsMessagesSchema.parse(data);
    });
  }

  function startTyping() {
    const params = {
      srcUser: userStore.user.userId,
      type: 'sms-shared',
      srcNumber: activeFrom.value,
      dstNumber: activeTo.value,
    };

    clearTimeout(timeout.value);

    timeout.value = setTimeout(() => {
      socket.value?.emit('cancel-typing', params);
    }, 5 * Time.Second);

    socket.value?.emit('typing', params);
  }

  function cancelTyping() {
    clearTimeout(timeout.value);

    socket.value?.emit('cancel-typing', {
      srcUser: userStore.user.userId,
      type: 'sms-shared',
      srcNumber: activeFrom.value,
      dstNumber: activeTo.value,
    });
  }

  function onTyping() {
    socket.value?.on('typing-sms-shared', (response: TypingResponse) => {
      if (!typing.value[response.dstNumber]) {
        typing.value = {
          ...typing.value,
          [response.dstNumber]: [],
        };

        const directory = userStore.directory(response.srcUser.toString());
        typing.value[response.dstNumber].push({
          id: response.srcUser,
          isTyping: true,
          name: directory ? directory.userName : '',
        });
        return;
      }

      const { findIndexByValue } = useArray(typing.value[response.dstNumber]);

      const index = findIndexByValue('id', response.srcUser);

      if (index !== -1) {
        typing.value[response.dstNumber][index].isTyping = true;
      }
    });
  }

  function onCancelTyping() {
    socket.value?.on('cancel-typing-sms-shared', (response: TypingResponse) => {
      const { findIndexByValue } = useArray(typing.value[response.dstNumber]);

      const index = findIndexByValue('id', response.srcUser);

      if (index !== -1) {
        typing.value[response.dstNumber][index].isTyping = false;
      }
    });
  }

  function hasMyNumber(number: string) {
    return (
      userStore.myNumbers
        .filter((n) => n.type === 'sms')
        .filter((n) => n.numbersUnmasked.includes(number)).length > 0
    );
  }

  function newSms() {
    socket.value?.on(
      'new-sms-unknown',
      async (response: SharedSmsMessageDb) => {
        if (response.type !== 'sms-shared') return;

        await db.shared_sms.put(response);

        updateLastMessageByContact(response);

        if (
          [response.src_number, response.dst_number].includes(activeTo.value)
        ) {
          checkSmsRead(activeTo.value);
        }

        if (
          response.src_user !== userStore.user.userId &&
          !hasMyNumber(response.src_number)
        ) {
          toastStore.newMessage({
            id: window.crypto.randomUUID(),
            title: phoneMasked(response.src_number),
            message: response.content,
            type: 'directory',
            url: router.resolve({
              name: RouteName.SharedSms,
            }),
            avatarUrl: ContactIcon,
            variant: 'message',
          });
        }
      },
    );
  }

  function updateSms() {
    socket.value?.on(
      'update-sms-unknown',
      async (response: SharedSmsMessageDb) => {
        if (response.type !== 'sms-shared') return;

        await db.shared_sms.put(response);
      },
    );
  }

  function listenersSms() {
    newSms();
    updateSms();
    onTyping();
    onCancelTyping();
  }

  return {
    fullContacts,
    contacts,
    messages,
    subscription,
    loading,
    activeFrom,
    activeTo,
    name,
    hasNewSms,
    typing,
    addNewSms,
    removeNewMessage,
    sendSms,
    getContacts,
    syncInitialMessages,
    loadMoreMessages,
    checkSmsRead,
    getSmsObservable,
    getSmsDb,
    startTyping,
    cancelTyping,
    listenersSms,
  };
});
