import { z } from 'zod';

const statusSchema = z.array(
  z.object({
    status: z.string(),
    date: z.coerce.date(),
  }),
);

export const sharedSmsMessageResponseSchema = z.object({
  id: z.string(),
  content: z.string(),
  src_number: z.string(),
  src_user: z.number().nullable(),
  dst_number: z.string(),
  dst_user: z.number().nullable(),
  type: z.string(),
  status: z
    .unknown()
    .transform((str) => {
      if (typeof str === 'string') {
        return JSON.parse(str);
      }

      return str;
    })
    .pipe(statusSchema),
  created_at: z.coerce.date(),
});

export const sharedSmsResponseSchema = sharedSmsMessageResponseSchema.transform(
  (obj) => ({
    id: obj.id,
    content: obj.content,
    srcNumber: obj.src_number,
    srcUser: obj.src_user,
    dstNumber: obj.dst_number,
    dstUser: obj.dst_user,
    type: obj.type,
    status: obj.status,
    createdAt: obj.created_at,
  }),
);

export const sharedSmsMessagesSchema = z.array(sharedSmsResponseSchema);

export const shardedSmsContactSchema = z
  .object({
    number_hash: z.string(),
    number: z.string(),
    first_message_at: z.coerce.date(),
    last_message_at: z.coerce.date(),
    last_message: sharedSmsMessageResponseSchema,
    qtd_messages_not_read: z.number(),
    is_directory: z.boolean(),
    type: z.enum(['sms-shared', 'sms']),
  })
  .transform((obj) => ({
    numberHash: obj.number_hash,
    number: obj.number,
    firstMessageAt: obj.first_message_at,
    lastMessageAt: obj.last_message_at,
    lastMessage: {
      id: obj.last_message.id,
      content: obj.last_message.content,
      srcNumber: obj.last_message.src_number,
      srcUser: obj.last_message.src_user,
      dstNumber: obj.last_message.dst_number,
      dstUser: obj.last_message.dst_user,
      type: obj.last_message.type,
      status: obj.last_message.status,
      createdAt: obj.last_message.created_at,
    },
    isDirectory: obj.is_directory,
    type: obj.type,
    messagesNotRead: obj.qtd_messages_not_read,
  }));

export const typingsSchema = z.object({
  srcUser: z.number(),
  type: z.string(),
  srcNumber: z.string(),
  dstNumber: z.string(),
});

export const shardedSmsContactsSchema = z.array(shardedSmsContactSchema);

export type SharedSmsContactResponse = z.input<typeof shardedSmsContactSchema>;
export type SharedSmsContact = z.infer<typeof shardedSmsContactSchema>;
export type SharedSmsMessageDb = z.infer<typeof sharedSmsMessageResponseSchema>;
export type SharedSMSMessage = z.infer<typeof sharedSmsResponseSchema>;
export type TypingResponse = z.infer<typeof typingsSchema>;
