import { defineStore } from 'pinia';
import {
  useActivityStore,
  useConferenceStore,
  useGroupMessageStore,
  useMessageStore,
  usePresenceForMeetingStore,
  useSharedSmsStore,
  useSmsMessageStore,
  useStatusStore,
  useTypingStore,
  useUserStore,
} from '@/stores';
import { ref } from 'vue';
import { io, Socket } from 'socket.io-client';
import { getTeams, useDirectory, useLogout } from '@/composables';

export const useSocketConnectionStore = defineStore('socket', () => {
  const userStore = useUserStore();
  const ioClient = ref<Socket>();
  const { updateStatus, startListeners } = useStatusStore();
  const { onListenersIO } = useConferenceStore();
  const { onListenersMessage, getMessagesNotRead, onDeleteMessage } =
    useMessageStore();
  const { listenersGroupMessage } = useGroupMessageStore();
  const { listenersTyping } = useTypingStore();
  const { listPresenceConference, presenceConference } =
    usePresenceForMeetingStore();
  const { listenersActivity } = useActivityStore();
  const smsMessageStore = useSmsMessageStore();
  const sharedSmSStore = useSharedSmsStore();

  function joinWebSocket() {
    ioClient.value?.on('connect', () => {
      console.log('--> JOINING SOCKET', userStore.currentAccount);
      joinRoom();
    });

    ioClient.value?.on('disconnect', () => {
      leaveSocket();
      initializeSocket();
    });
  }

  function joinRoom() {
    if (
      ioClient.value?.connected &&
      userStore.currentAccount?.accountCode &&
      userStore.user.userId
    ) {
      ioClient.value?.emit(
        'join-room',
        userStore.currentAccount.accountCode,
        `${userStore.user.userId}${userStore.currentAccount.accountCode}`,
      );
    }
  }

  function leaveSocket() {
    console.log('--> LEAVING SOCKET', userStore.currentAccount);
    ioClient.value?.emit(
      'leave-room',
      userStore.currentAccount.accountCode,
      userStore.user.userId + userStore.currentAccount.accountCode,
    );

    ioClient.value?.disconnect();
  }

  function initializeSocket(startAllServices = true) {
    ioClient.value = io(import.meta.env.VITE_WEBSOCKET, {
      transports: ['websocket'],
      auth: {
        token: userStore.user.authToken,
      },
    });

    if (!startAllServices) {
      return;
    }
    joinWebSocket();
    updateStatus();
    onListenersIO();
    onListenersMessage();
    listenersGroupMessage();
    listenersTyping();
    getMessagesNotRead();
    startListeners();
    teamUpdateListener();
    removeUserListener();
    listPresenceConference(
      ioClient.value,
      userStore.currentAccount.accountCode,
    );
    presenceConference(ioClient.value, userStore.currentAccount.accountCode);
    onDeleteMessage(ioClient.value);
    listenersActivity();
    smsMessageStore.listenersSmsMessage();
    sharedSmSStore.listenersSms();
  }

  function updateTeam() {
    ioClient.value?.emit('team-updated', userStore.currentAccount.accountCode);
  }

  function deleteUser(userId: string) {
    const userIdSocket = userId + userStore.currentAccount.accountCode;
    ioClient.value?.emit('deleted-user', userIdSocket);
  }

  function teamUpdateListener() {
    ioClient.value?.on('team-updated', async () => {
      await getTeams();
      await useDirectory();
    });
  }
  function removeUserListener() {
    ioClient.value?.on('user-deleted', async () => {
      await useLogout();
    });
  }

  return {
    ioClient,
    initializeSocket,
    leaveSocket,
    updateTeam,
    joinRoom,
    deleteUser,
  };
});
