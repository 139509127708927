import {
  useAccountStore,
  useAuthStore,
  useUserStore,
  useSocketConnectionStore,
  useWebrtcStore,
} from '@/stores';
import axios, { AxiosError } from 'axios';
import type {
  AccountNumber,
  ApiDataException,
  AuthResponseData,
  MeAuth,
  NumberSettings,
  RefreshTokenAuth,
  SettingResponse,
  SettingResponseData,
  User,
} from '@/types';
import { addMinutes } from 'date-fns';
import { EndpointApi, RouteName } from '@/enums';
import { useRouter } from 'vue-router';
import {
  useDirectory,
  useGetFavorite,
  useAllNumbers,
  useSwitchCustomer,
  getTeams,
  getTeamsNumbers,
  useGetGroup,
  useGetImportedContacts,
  useAttachments,
} from '@/composables';
import {
  axiosDefault,
  closeDB,
  getNameNumberSettings,
  phoneMasked,
  removeDatabase,
} from '@/utils';

interface LoginData {
  email: string;
  password: string;
}

export async function useLogin(data: LoginData) {
  try {
    const response = await axios.post<AuthResponseData>(
      EndpointApi.Login,
      data,
    );

    return setUserSession(response.data);
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useRefreshToken() {
  const userStore = useUserStore();
  const authStore = useAuthStore();
  try {
    const response = await axios.get<RefreshTokenAuth>(
      EndpointApi.RefreshToken,
      {
        headers: {
          Authorization: `Bearer ${userStore.user.refreshToken}`,
        },
      },
    );

    const result = response.data;
    userStore.setToken(result.token, result.refreshToken);
    authStore.isAuthenticatedTruthy();
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function removeDataLocal() {
  const webRtcStore = useWebrtcStore();

  webRtcStore.unRegisterAll();
  const { leaveSocket } = useSocketConnectionStore();
  leaveSocket();
  await removeDatabase();
}

export async function useLogout(redirection = true) {
  await axios.get(EndpointApi.Logout);
  const authStore = useAuthStore();
  const webRtcStore = useWebrtcStore();

  webRtcStore.unRegisterAll();
  const { leaveSocket } = useSocketConnectionStore();
  authStore.isAuthenticatedFalsy();
  leaveSocket();

  //clear storage
  setTimeout(async () => {
    deleteAllCookies();

    const recent = localStorage.getItem('recent');
    localStorage.clear();
    if (recent != null) {
      localStorage.setItem('recent', recent);
    }

    await removeDatabase();
    closeDB();

    if (redirection) {
      window.location.href = '/';
    }
  }, 800);
}

function deleteAllCookies() {
  const allCookies = document.cookie.split(';');

  allCookies.forEach((cookie) => {
    document.cookie = cookie + '=;expires=' + new Date(0).toUTCString();
  });
}

export async function useMe() {
  const authStore = useAuthStore();
  const userStore = useUserStore();

  try {
    if (!userStore.user.refreshToken) {
      return useLogout();
    }

    const response = await axios.get<MeAuth>(EndpointApi.Me);
    const data = response.data;

    await updateUserStore(data);

    if (data.settings.length) {
      const isFistLogin: SettingResponseData | undefined = data.settings.find(
        (setting) => setting.pref_name === 'first_login',
      );

      //block the other loads if the user is first login
      if (isFistLogin && isFistLogin?.pref_value !== '0') {
        if (data.accounts.length === 1) {
          await useSwitchCustomer(data.accounts[0].account_code);
        }

        return;
      }
    }

    userStore.setCurrentAccount({
      customer: data.current_customer.Customer,
      spCode: data.current_customer.SP_Code,
      accountCode: data.current_customer.Account_Code,
    });

    await syncUserData();

    return data;
  } catch (err) {
    const error = err as AxiosError<ApiDataException>;

    if (error.response?.status === 401 || error.response?.status === 422) {
      authStore.isAuthenticatedFalsy();
      const router = useRouter();
      router.push({ name: RouteName.Login });
    }

    throw error;
  }
}

export async function updateUserStore(data: MeAuth) {
  const userStore = useUserStore();
  const accountStore = useAccountStore();

  userStore.setSettings(data.settings);
  userStore.setPublicStorage(data.public_storage);
  userStore.setCurrentLevel(data.current_level);
  userStore.setSip(data.sip);
  userStore.setSupportLink(data.support_link);

  userStore.removeMyNumbers();
  for (const row of data.my_numbers) {
    const settings: NumberSettings = {};
    for (const setting of row.settings) {
      const name = getNameNumberSettings(setting.pref_name, setting.pref_group);
      if (name)
        settings[name as keyof NumberSettings] = !!parseInt(
          setting.pref_value,
          10,
        );
    }
    userStore.setMyNumbers({
      name: row.CallerName,
      number: phoneMasked(row.Number),
      numbersUnmasked: row.Number,
      password: row.Password,
      status: row.Status,
      accountCode: row.AccountCode,
      hostname: row.Hostname,
      active: settings.active ?? true,
      dnd: settings.dnd ?? false,
      type: row.Type.toLowerCase() as AccountNumber['type'],
    });
  }

  accountStore.accounts = [];
  for await (const account of data.accounts) {
    const dataAccount = {
      accountCode: account.account_code,
      customer: account.customer,
      spCode: account.sp_code,
    };
    accountStore.setAccounts(dataAccount);
  }
}

export async function syncUserData() {
  const { getAttachmentsSettings } = useAttachments();

  try {
    await Promise.all([
      useAllNumbers(),
      useDirectory(),
      useGetGroup(),
      getTeams(),
      getTeamsNumbers(),
      useGetImportedContacts(),
      getAttachmentsSettings(),
    ]);

    await useGetFavorite();
  } catch (e) {
    console.log('syncUserData', e);
  }
}

export async function useUpdateFirstLogin(status = false) {
  const userStore = useUserStore();
  try {
    const params = {
      pref_name: 'first_login',
      pref_group: 'user',
      pref_value: status,
    };

    const response = await axios.put<SettingResponse>(
      EndpointApi.Setting,
      params,
    );
    const data = response.data;
    userStore.setSettings(data.settings);

    return data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function loginFromArenaJWT(jwt: string) {
  try {
    const response = await axios.post<AuthResponseData>(
      EndpointApi.LoginWithWithArenaJWT,
      [],
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return setUserSession(response.data);
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

async function setUserSession(result: AuthResponseData) {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const userApi = result.user;

  const user: User = {
    userId: userApi.user_id,
    userName: userApi.user_name,
    authToken: result.token,
    refreshToken: result.refreshToken,
    email: userApi.email,
    ipAddress: userApi.ip_address,
    phoneNumber: userApi.phone_number,
    createdBy: userApi.created_by,
    createdAt: userApi.created_at,
    updatedAt: userApi.updated_at,
    deletedAt: userApi.deleted_at,
  };

  userStore.setUser(user);
  authStore.isAuthenticatedTruthy();
  const sessionLifeTime = addMinutes(
    new Date(),
    Number(result.session_lifetime),
  );
  authStore.setSessionLifeTime(sessionLifeTime);
  axiosDefault();
  return result;
}
