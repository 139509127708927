import Dexie, { type Table } from 'dexie';
import type {
  ChatMessage,
  GroupMessage,
  UnknownSms,
  ActivityDB,
  ContactDb,
} from '@/utils/db/types';
import type { SharedSmsMessageDb } from '@/schemas';

export class MySubClassedDexie extends Dexie {
  chat_messages!: Table<ChatMessage>;
  group_messages!: Table<GroupMessage>;
  unknown_sms!: Table<UnknownSms>;
  shared_sms!: Table<SharedSmsMessageDb>;
  activities!: Table<ActivityDB>;
  contacts!: Table<ContactDb>;

  constructor() {
    super('db-switch');
    this.version(6).stores({
      chat_messages:
        'id,account_code,content,src_user,dst_user,read,type,created_at,updated_at,[src_user+account_code], [dst_user+account_code]',
      group_messages:
        'id,account_code,group_id,content,src_user,read,type,created_at,updated_at, [group_id+account_code]',
      unknown_sms:
        'id,content,src_number,src_user,dst_number,status,created_at',
      shared_sms:
        'id,content,src_number,src_user,dst_user,dst_number,type,status,created_at, [src_number+dst_number]',
      activities:
        'id,call_recorded,call_recorded_file,call_type,caller_id,created_at,device,direction,disconnect_cause,dst_account_code,dst_number,dst_user,duration,finished_at,info,number_hash,queue_log,raw_id,read,release_cause,src_account_code,src_number,src_user,transcription,type, [src_user+src_account_code], [dst_user+dst_account_code]',
      contacts:
        'id, hash, user_id, first_name, last_name, emails, phones, provider, type, email_source_provider, id_contact_provider, street, city, state, postcode, country, company, job_title, contact_account_code, contact_user_id, email_from_pbx, user_name, account_code, email, assigned_numbers, user_type',
    });
  }
}

export const db = new MySubClassedDexie();

export async function removeDatabase() {
  return db.delete();
}

export function closeDB() {
  return db.close();
}
