import { useI18n } from 'vue-i18n';
import type { UserTypeAccount } from '@/types';
import { Account } from '@/enums';
import { capitalize } from 'lodash';
import en from '@/locales/en.json';

export function customLabel(option: string) {
  const { t } = useI18n();

  if (option in en) {
    return capitalize(t(option));
  }
}

export function getAllNumbers(value: string) {
  return value.replace(/[a-zA-Z]/g, '');
}

export function getMeasureUnit(value: string) {
  return value.replace(/\d/g, '');
}

export function getUserStatus(userDBStatus: UserTypeAccount | undefined) {
  if (userDBStatus === undefined) return '';

  return Account[userDBStatus];
}

export function addThreeDots(word: string, length: number) {
  const wordLengthMin = length;
  const newWord = word.substring(0, length);
  return word.length < wordLengthMin ? word : `${newWord}...`;
}

export function removeSpaces(world: string) {
  return world.replace(/ /g, '');
}

export function isNumeric(world: string) {
  const expression = /^[0-9]+$/;
  return expression.test(world);
}

export function toCamelCase(str: string) {
  return str
    .replace(/[-_\s]+(.)/g, (_, p1) => p1.toUpperCase())
    .replace(/^[A-Z]/, (match) => match.toLowerCase())
    .replace(/([A-Z])([A-Z])/g, '$1_$2')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
    .replace(/_([a-z])/g, (match) => match[1].toUpperCase());
}

export function toSnakeCase(str: string): string {
  const regex =
    /([A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[0-9]+|-)/g;

  const matches = str.match(regex);

  if (!matches) return str;

  return matches.map((part) => part.replace(/-/g, '').toLowerCase()).join('_');
}

export function checkByteLimit(text: string, limit: number): boolean {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(text).length;
  console.log({ bytes });
  return bytes <= limit;
}
