import { defineStore } from 'pinia';
import { useSocketConnectionStore } from '@/stores/socket-connection';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { RouteName, TypingEnum } from '@/enums';
import { useUserStore } from '@/stores/user';
import type {
  IndexesTyping,
  IndexesTypingGroup,
  ResponseGroupTyping,
  ResponseTyping,
  Typing,
} from '@/types';

export const useTypingStore = defineStore('typing', () => {
  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const socket = computed(() => socketConnectionStore.ioClient);
  const route = useRoute();

  const typeChat = ref<'group' | 'directory'>();
  const isTyping = ref(false);
  const typings = ref<Typing>({});
  const timeoutDirectory = ref<ReturnType<typeof setTimeout>>();
  const timeoutHandleTyping = ref<ReturnType<typeof setTimeout>>();
  const timeoutGroup = ref<ReturnType<typeof setTimeout>>();

  function setTypeChat() {
    const routeName = route.name as string;

    if (routeName === RouteName.Directory) {
      typeChat.value = 'directory';
    }

    if (routeName === RouteName.Group) {
      typeChat.value = 'group';
    }
  }

  function handleTyping(event: 'typing' | 'cancel-typing') {
    setTypeChat();
    const id = parseInt(route.params.id as string, 10);

    if (typeChat.value === 'directory') {
      socket.value?.emit(event, {
        srcUser: userStore.user.userId,
        accountCode: userStore.currentAccount.accountCode,
        dstUser: id,
        type: typeChat.value,
      });
    }

    if (typeChat.value === 'group') {
      socket.value?.emit(event, {
        srcUser: userStore.user.userId,
        accountCode: userStore.currentAccount.accountCode,
        type: typeChat.value,
        groupId: id,
        users: userStore.groupUsersById(id),
      });
    }
  }
  function handleCancelClickTyping() {
    isTyping.value = false;
    handleTyping('cancel-typing');
    clearTimeout(timeoutHandleTyping.value);
  }

  function handleKeydownTyping() {
    const editor = document.querySelector(
      '#tip-tap .ProseMirror',
    ) as HTMLElement;
    clearTimeout(timeoutHandleTyping.value);
    isTyping.value = false;

    editor.addEventListener('keydown', (event) => {
      if (event.key !== 'Enter') {
        if (!isTyping.value) {
          isTyping.value = true;
          handleTyping('typing');
        }

        timeoutHandleTyping.value = setTimeout(() => {
          isTyping.value = false;
        }, TypingEnum.Limit);
      }
    });
  }

  function typingDirectory() {
    socket.value?.on('typing-directory', (typing: ResponseTyping) => {
      if (typing.type === 'directory') {
        clearTimeout(timeoutDirectory.value);
        const indexTyping: IndexesTyping = {};
        indexTyping[typing.srcUser] = { isTyping: true };
        typings.value[typing.type] = {
          ...typings.value[typing.type],
          ...indexTyping,
        };
        timeoutDirectory.value = setTimeout(() => {
          (typings.value[typing.type] as IndexesTyping)[
            typing.srcUser
          ].isTyping = false;
        }, TypingEnum.Limit);
      }
    });
  }

  function cancelTypingDirectory() {
    socket.value?.on('cancel-typing-directory', (typing: ResponseTyping) => {
      if (typing.type === 'directory') {
        clearTimeout(timeoutDirectory.value);
        const indexTyping: IndexesTyping = {};
        indexTyping[typing.srcUser] = { isTyping: false };
        typings.value[typing.type] = {
          ...typings.value[typing.type],
          ...indexTyping,
        };
        clearTimeout(timeoutDirectory.value);
      }
    });
  }

  function typingGroup() {
    socket.value?.on('typing-group', (typing: ResponseGroupTyping) => {
      if (typing.type === 'group') {
        console.log(timeoutGroup.value);
        clearTimeout(timeoutGroup.value);
        console.log(timeoutGroup.value);
        const indexTyping: IndexesTypingGroup = {};
        indexTyping[typing.groupId] = [typing.srcUser];
        typings.value[typing.type] = {
          ...typings.value[typing.type],
          ...indexTyping,
        };
        timeoutGroup.value = setTimeout(() => {
          (typings.value[typing.type] as IndexesTypingGroup)[typing.groupId] =
            [];
        }, TypingEnum.Limit);
        console.log(timeoutGroup.value);
      }
    });
  }

  function cancelTypingGroup() {
    socket.value?.on('cancel-typing-group', (typing: ResponseGroupTyping) => {
      if (typing.type === 'group') {
        clearTimeout(timeoutGroup.value);
        const indexTyping: IndexesTypingGroup = {};
        indexTyping[typing.groupId] = [];
        typings.value[typing.type] = {
          ...typings.value[typing.type],
          ...indexTyping,
        };
        clearTimeout(timeoutGroup.value);
      }
    });
  }

  function listenersTyping() {
    typingDirectory();
    cancelTypingDirectory();
    typingGroup();
    cancelTypingGroup();
  }

  return {
    typings,
    handleKeydownTyping,
    listenersTyping,
    cancelClickTyping: handleCancelClickTyping,
  };
});
